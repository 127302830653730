<script>
  import { fade } from "svelte/transition";
  import { addy } from "./stores.js";
  import axios from "axios";
  import { draw } from "svelte/transition";
  import { onMount } from "svelte";
  let blobs_left = new Array(3);
  let blobs_right = new Array(50);
  let isAuto = true;
  let addy_local;
  const unsubscribe = addy.subscribe(value => {
    // console.log(value);
    addy_local = value;
  });
  export let reg_ex_mobile = /^[0]{1}[4]{1}[0-9]{8}$/; //0 426223349
  export let mobile_country = "+61";
  export let mobile_format = "04XX XXX XXX";
  export let mobile_placeholder = "0404567890"; //"09171234567";
  export let mobile_minlength = 10;
  export let mobile_maxlength = 11;
  export let addy_zip_format = 4;
  export let country_code;
  export let addy_auto;
  export let addy_all = {
    addy_street: "",
    addy_city: "",
    addy_state: "",
    addy_country: "",
    addy_zip: ""
  };
  export let selectedDate;
  export let begin_after;
  export let duration;
  export let mobile;
  export let email;
  export let job_specific;
  export let notes = " ";

  export let name_first;
  export let name_last;
  export let booking_modify;
  export let error_format = "border border-red-400";
  // export let error_addy_city;
  // export let error_addy_zip;
  // export let error_addy_state;
  // export let error_addy_street;

  // export let error_bk_date;
  export let error_begin_after;
  export let error_begin_date;
  export let error_email;
  export let error_mobile;
  export let error_name_first;
  export let error_name_last;
  export let token = "ok";
  export let job;
  //  export let password;
  export let loading = false;
  export let loading_success = false;
  export let loading_failure = false;
  export let jobs_sp_annual = [
    {
      id: 2,
      text: "Backflow",
      val: ["Plumbing Water", "Backflow", "Installation"]
    },

    {
      id: 6,
      text: "Fire Service",
      val: ["Plumbing Water", "Fire Service", "Annual Test"]
    },

    { id: 13, text: "Other", val: ["Plumbing Water", "General", "Quote"] }
  ];
  export let jobs_sp = [
    {
      id: 1,
      text: "Select",
      val: ["Plumbing Water", "General", "Quote"]
    },
    {
      id: 2,
      text: "Backflow",
      val: ["Plumbing Water", "Backflow", "Installation"]
    },
    {
      id: 3,
      text: "Check Meter",
      val: ["Plumbing Water", "Check Meter", "Upgrade"]
    },
    {
      id: 4,
      text: "Drain",
      val: ["Plumbing General", "Installation", "Fixture"]
    },
    {
      id: 5,
      text: "Hot water service",
      val: ["Plumbing Water", "Hot water service", "Installation"]
    },
    {
      id: 6,
      text: "Fire Service",
      val: ["Plumbing Water", "Fire Service", "Annual Test"]
    },
    {
      id: 7,
      text: "General",
      val: ["Plumbing General", "General", "Repair of leak"]
    },
    {
      id: 8,
      text: "Sink",
      val: ["Plumbing General", "Installation", "Fixture"]
    },
    {
      id: 9,
      text: "Shower",
      val: ["Plumbing General", "Installation", "Fixture"]
    },
    {
      id: 10,
      text: "Tap",
      val: ["Plumbing General", "Installation", "Fixture"]
    },
    {
      id: 11,
      text: "Toilet",
      val: ["Plumbing General", "Installation", "Fixture"]
    },
    {
      id: 12,
      text: "Water Pipe",
      val: ["Plumbing Water", "Water Pipe", "Repair of leak"]
    },
    { id: 13, text: "Other", val: ["Plumbing Water", "General", "Quote"] }
  ];

  let final = jobs_sp_annual.concat(jobs_sp);

  let currentDate = new Date();
  let minDate = `${currentDate.getFullYear()}-${currentDate.getMonth() +
    1}-${currentDate.getDate() + 1}`;

  onMount(async () => {
    // signIn("nanniesonwork@riyo.io", "kzJkyPwZcuyQhkiRqHJeoLF9");
    signIn("logistics_test@riyo.io", "9DqgiGzHJQCVYuagje.WLQE");
  });
  function select_country() {
    console.log({ country_code: country_code });
    switch (country_code) {
      case "AU":
        reg_ex_mobile = /^[0]{1}[4]{1}[0-9]{8}$/; //0 426223349
        mobile_country = "+61";
        mobile_format = "04XX XXX XXX";
        mobile_placeholder = "0404567890";
        mobile_minlength = 10;
        mobile_maxlength = 10;
        addy_zip_format = 4;
        break;
      case "NZ":
        reg_ex_mobile = /^[0]{1}[2]{1}[0-9]{1}[0-9]{6}$/;
        mobile_country = "+64";
        mobile_format = "02XX XXX XX";
        mobile_placeholder = "020456789";
        mobile_minlength = 9;
        mobile_maxlength = 10;
        addy_zip_format = 4;

        break;
      case "SG":
        reg_ex_mobile = /^[8-9]{1}[0-9]{7}$/;
        mobile_country = "+65";
        mobile_format = "8XXX XXXX";
        mobile_placeholder = "82045678";
        mobile_minlength = 8;
        mobile_maxlength = 8;
        addy_zip_format = 6;
        break;

      case "PH":
        reg_ex_mobile = /^[0]{1}[8-9]{1}[0-9]{9}$/;
        mobile_country = "+63";
        mobile_format = "09XXXXXXXXX"; //+63 9 XXX XXX XXX
        mobile_placeholder = "09211111111";
        mobile_minlength = 11;
        mobile_maxlength = 11;
        addy_zip_format = 4;
        break;

      case "ID":
        reg_ex_mobile = /^[0]{1}[8]{1}[0-9]{2}[0-9]{8}$/;
        mobile_country = "+63";
        mobile_format = "8NNXXXXXXXX"; //+62 8nn XXXX XXXX
        mobile_placeholder = "082212345678";
        mobile_minlength = 12;
        mobile_maxlength = 12;
        addy_zip_format = 5;
        break;

      case "IND":
        reg_ex_mobile = /^[6-9]{1}[0-9]{9}$/;
        mobile_country = "+91";
        mobile_format = "9XXXXX XXXX";
        mobile_placeholder = "92045367834";
        mobile_minlength = 10;
        mobile_maxlength = 10;
        addy_zip_format = 6;
        break;
    }
  }
  function getMobileES164(input_mobile) {
    const country = country_code;

    const country_pfxs = {
      AU: "+61",
      NZ: "+64",
      IND: "+91",
      SG: "+65",
      PH: "+63",
      ID: "+62"
    };

    const pfx = country_pfxs[country];

    if (
      country_code == "AU" ||
      country_code == "NZ" ||
      country_code == "PH" ||
      country_code == "ID"
    ) {
      return `${pfx}${input_mobile.substring(1)}`;
    } else {
      return `${pfx}${input_mobile}`;
    }
  }
  async function form_submit() {
    // console.log("form_submit");

    error_begin_date = "";
    error_begin_after = "";
    error_name_first = "";
    error_name_last = "";
    if (!selectedDate) {
      return (error_begin_date = error_format);
    }
    if (!begin_after) {
      return (error_begin_after = error_format);
    }

    if (!name_first) {
      return (error_name_first = error_format);
    }
    if (!name_last) {
      return (error_name_last = error_format);
    }
    select_country();

    if (!mobile) {
      return (error_mobile = error_format);
    } else {
      const re = /^[0]{1}[4]{1}[0-9]{8}$/;
      //  const v = re.test(String(mobile).toLowerCase());
      const v = reg_ex_mobile.test(String(mobile).toLowerCase());
      if (!v) {
        return (error_mobile = error_format);
      }
    }
    const mobileE164 = getMobileES164(mobile);
    if (!email) {
      return (error_email = error_format);
    } else if (email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const v = re.test(String(email).toLowerCase());
      if (!v) {
        return (error_email = error_format);
      }
    }
    if (!addy) {
      alert("no address");
    }
    // if (!addy_street) {
    //   return (error_addy_street = error_format);
    // }
    // if (!addy_city) {
    //   return (error_addy_city = error_format);
    // }
    // if (!addy_state) {
    //   return (error_addy_state = error_format);
    // }

    // if (!addy_zip || addy_zip.length !== 4) {
    //   return (error_addy_zip = error_format);
    // }

    const now = new Date();
    const now_string = now.toString();
    const beg_test = begin_after.split(":");
    const beg_nu = Number(beg_test[0]);
    const begin_hours_am_pm = await new Promise(res => {
      if (beg_nu > 12) {
        const beg_sub = beg_nu - 12;
        res(`${beg_sub}:${beg_test[1]}:00 PM`);
      } else if (beg_nu == 12) {
        res(`12:${beg_test[1]}:00 PM`);
      } else {
        res(`${beg_nu}:${beg_test[1]}:00 AM`);
      }
    });
    const end_test = begin_after.split(":");
    let end_nu = 0;
    if (Number(duration) < 1) {
      end_nu = Number(end_test[0]) + 1;
    } else {
      end_nu = Number(end_test[0]) + Number(+duration); //without duration
    }

    const end_hours_am_pm = await new Promise(res => {
      if (end_nu > 23) {
        res(`23:59:00 PM`);
      } else if (end_nu > 12) {
        const end_sub = end_nu - 12;
        res(`${end_sub}:${end_test[1]}:00 PM`);
      } else if (end_nu == 12) {
        res(`12:${end_test[1]}:00 PM`);
      } else {
        res(`${end_nu}:${end_test[1]}:00 AM`);
      }
    });
    const nw = Math.floor(Date.now() / 1000);
    const date_slash_pre = selectedDate.split("-");
    const date_slash = `${date_slash_pre[2]}/${date_slash_pre[1]}/${date_slash_pre[0]}`;
    // console.log(`${date_slash} ${begin_hours_am_pm} a`);
    // console.log(`${date_slash} ${end_hours_am_pm} b`);
    let job_final;
    // console.log({ job_specific });
    for (const job of final) {
      if (job_specific == job.id) {
        job_final = job;
      }
    }
    // console.log({ job_final });
    // console.log({ addy_local });
    if (addy_local.addy) {
      addy_auto = addy_local.addy;

      let place = addy_local.place;
      // addy_street: null,
      //       addy_zip: null,
      //       addy_city: null,
      //       addy_country: "",
      //       addy_state: null,
      const address_map = {
        street_number: "addy_street",
        route: "addy_street",
        locality: "addy_street",
        administrative_area_level_2: "addy_city",
        administrative_area_level_1: "addy_state",
        country: "addy_country",
        postal_code: "addy_zip"
      };
      const addy_fields = Object.values(address_map);
      for (var i = 0; i < addy_fields.length; i++) {
        var var_name = addy_fields[i];
        if (addy_all[var_name]) addy_all[var_name] = "";
      }
      const setflags = {
        addy_street: false,
        addy_city: false,
        addy_state: false,
        addy_country: false,
        addy_zip: false
      };

      for (var i = 0; i < place.length; i++) {
        var addressType = place[i].types[0];
        if (address_map[addressType]) {
          const var_name = address_map[addressType];
          const val = place[i]["long_name"];
          if (setflags[var_name]) {
            addy_all[var_name] += `,${val}`;
          } else {
            addy_all[var_name] = val;
            setflags[var_name] = true;
          }
        }
      }
      // console.log(`${addy_all.addy_street},
      //  ${addy_all.addy_zip},
      //  ${addy_all.addy_city},
      //  ${addy_all.addy_country},
      //  ${addy_all.addy_state}`);

      addy_auto = {
        ...addy_auto,
        ...{
          address_line1: addy_all.addy_street,
          address_line2: "",
          city: addy_all.addy_city,
          state: addy_all.addy_state,
          postal_code: addy_all.addy_zip,
          country: addy_all.addy_country
        }
      };
      // console.log(this.addy_auto);
    } else {
      addy_all.addy_auto = null;
      addy_all.addy_street = null;
      addy_all.addy_zip = null;
      addy_all.addy_city = null;
      addy_all.addy_country = null;
      addy_all.addy_state = null;
    }
    debugger;
    booking_modify = {
      shape: "shapeRiyo",
      Duration: `${duration * 60 * 60}`,
      Tolerance: 120,
      JobNo: `online`, //:"869381",  done
      TaskNo: `${nw}`, //:"1011507", ?
      CreateDate: "25/02/2020 11:39:37 AM", //:"25/02/2020 11:39:37 AM",
      JobStatus: "draft", //:"Open ",
      Priority: "8", //:"8",  ?
      MustStartBy: `${date_slash} ${begin_hours_am_pm}`, //:"2/03/2020 8:00:00 AM", done
      MustFinishBy: `${date_slash} ${end_hours_am_pm}`, //:"2/03/2020 10:00:00 AM", done
      WorkCompleteTime: `${date_slash} ${end_hours_am_pm}`, //:"1/01/0001 12:00:00 AM", ?
      EmployeeName: "Riyo", //:"Debbie Murray", ignore
      CreatedBy: "Riyo", //:"SEe0209",
      CallerName: `${name_first} ${name_last}`, //:" Rushmini Perera",
      CallerPhoneNo: mobileE164, //:"0417770333", lookup
      CallerEmail: email, //:"rushminip@hotmail.com",
      CCT: job, // job_final.val[0], // "", // job_final.val[0], //`${job_final.val[0]} ${job_final.val[1]} ${job_final.val[2]}`, //:"Plumbing Water-Check Meter-Quote",
      Address: addy_auto, //`${addy_street}, ${addy_city}, ${addy_state}, ${addy_zip}, Australia`,
      AddressLine1: addy_all.addy_street,
      AddressLine2: "",
      City: addy_all.addy_city,
      State: addy_all.addy_state,
      PostalCode: addy_all.addy_zip,
      CustNum: [],
      Country: addy_all.addy_country,
      Contractor: "SEW", //:"South East Water", ignore
      Department: "SEW PriorityPlumbing", //:"SEW PriorityPlumbing", ignore
      Category: job, //job_final.val[0], //:"Plumbing Water", label
      Class: [], //:"Check Meter",  label
      Type: [], //:"Quote", label
      TaskStory: "", //:"[Murray,Debbie] [25/2/2020 11:39] [ALLOCATED]~Task Created,[Murray,Debbie] [25/2/2020 11:39] [ALLOCATED]~Property Number:36C//08162/53,[OT ] [Murray,Debbie] [25/2/2020 11:39] [ALLOCATION]\nTask Allocated to a Field Terminal : SEf0009,[Recall Reason] [Sharlassian,Pam] [26/2/2020 09:12] [ALLOCATED]~.,[OT ] [Sharlassian,Pam] [26/2/2020 09:12] [TASK RECALL]\nTask Recalled from the Field Terminal : SEf0009,[OT ] [Sharlassian,Pam] [26/2/2020 09:13] [ALLOCATION]\nTask Allocated to a Field Terminal : SEf0005,[Sharlassian,Pam] [2/3/2020 11:57] [ALLOCATED]~Quote emailed to customer \nQuote Fee Paid $60.00\nEFT-attached \nA-923347\n,[Sharlassian,Pam] [3/3/2020 07:53] [ALLOCATED]~CS17733\nAUTH 923347",
      ProblemDetail: notes, //:"Customer adv she wants a quote to move her Check Meter Aware Quote fee Booked 2-4"
      TaskDetail: "", //"e: info@morningtonmed.com.au\nC:Jill fulton\nP:59752633\nAnnual Back Flow Test \nPleae invoice customer \nLP booked 27/3/2020@8-10am\n",
      PrimaryAllocateeList: "Riyo" //"SEf0005"
    };

    // console.log({ booking_modify });
    sendBooking();
  }
  function resetPassword() {
    const public_key_sew = "AIzaSyC9o2Z6CzeLFtYR9XOjm4VLOMhzZ4KbSqk";
    const public_key_dev = "AIzaSyCSygyZocn2m_YKCz-G5-Z9AskT5njpF-A";
    const payload = {
      email,
      requestType: "PASSWORD_RESET"
    };
    axios
      .post(
        `   https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${public_key_dev}`,
        payload
      )
      .then(res => {
        // check here for response object https://firebase.google.com/docs/reference/rest/auth#section-create-email-password
        // console.log({ res });
        token = res.data.idToken;
        // console.log(token);
      })
      .catch(e => {
        // console.log({ e });
      });
  }
  function signIn(em, pw) {
    // loading = true;
    const public_key_sew = "AIzaSyC9o2Z6CzeLFtYR9XOjm4VLOMhzZ4KbSqk";
    const public_key_dev = "AIzaSyCSygyZocn2m_YKCz-G5-Z9AskT5njpF-A";
    const payload = {
      email: em, //email, //"public+sew@riyo.io",
      password: pw, //password, // "asdfgh",
      returnSecureToken: true
    };
    axios
      .post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${public_key_dev}`,
        payload
      )
      .then(res => {
        // check here for response object https://firebase.google.com/docs/reference/rest/auth#section-create-email-password
        // console.log({ res });
        token = res.data.idToken;
        // console.log(token);
        // loading = false;
        // loading_success = true;
        // setTimeout(() => {
        //   loading = false;
        //   loading_success = false;
        // }, 2000);
      })
      .catch(e => {
        // console.log({ e });
        loading = false;
      });
  }
  function clearForm() {
    selectedDate = null;
    begin_after = null;
    mobile = null;
    email = null;
    job_specific = null;
    job = null;
    notes = " ";
    addy_city = null;
    addy_state = null;
    addy_street = null;
    addy_zip = null;
    name_first = null;
    name_last = null;
    booking_modify = null;
  }
  function sendBooking() {
    // console.log({ token });
    loading = true;
    if (!token) {
      return;
    }
    const endpoint_sew =
      "https://australia-southeast1-riyo-v3.cloudfunctions.net/";
    const endpoint =
      "https://australia-southeast1-riyo-dev-au.cloudfunctions.net/";

    // axios.setHeader("Authorization", `Bearer ${token}`);
    const instance = axios.create({
      baseURL: `${endpoint}`,
      timeout: 20000,
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*"
      }
    });
    //    await this.$axios.setHeader("Access-Control-Allow-Origin", "*");
    // console.log({ booking_modify });

    return instance
      .post(`api_au/booking_widget`, booking_modify)
      .then(response => {
        // response.data.objects;
        // console.log({ response });
        loading_success = true;
        setTimeout(() => {
          loading_success = false;
          loading = false;
          clearForm();
        }, 6000);
      })
      .catch(e => {
        loading_failure = true;
        setTimeout(_ => {
          loading_failure = false;
          loading = false;
        }, 3000);
        // console.log({ e });
        // if (e.response) {
        //   alert(e.response.data.message);
        // }
        // if (e.message) {
        //   alert(e.message);
        // }
      });
  }
  import Map from "./Map.svelte";
  export let ready;
</script>

<style>

</style>

<svelte:head>
  <script
    defer
    async
    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCSygyZocn2m_YKCz-G5-Z9AskT5njpF-A&libraries=places&callback=initMap">

  </script>
</svelte:head>
<div class="bg-white text-white flex" />
<div class="flex justify-end pr-12 sm:pr-24 h-10 bg-white " />
<div class="h-24 w-full bg-white px-10 flex items-end justify-between">
  <img class="-mb-2 ml-2" src="images/logo_10_to_10.png" alt="10 to 10" />
  <div class=" lg:flex lg:items-center lg:justify-between ">

    <div class="flex flex-row text-white-300">
      <span class="relative z-0 inline-flex ">
        <p
          style=" font-family: 'Gloria Hallelujah', cursive; color:#9f9f9f"
          class="px-3">
          Connect with us:
        </p>

        <button type="button" class=" relative inline-flex items-center px-1">
          <a
            class=""
            target="_blank"
            href="https://www.facebook.com/riyo.platform"
            title="Facebook">
            <img src="/images/facebook-icon.png" alt="Facebook" />
          </a>
        </button>
        <button type="button" class=" relative inline-flex items-center px-3">
          <a
            class="
            "
            target="_blank"
            href="https://www.twitter.com/riyo_platform"
            title="Twitter">
            <img src="/images/twitter-icon.png" alt="Twitter" />
          </a>
        </button>

      </span>

    </div>
  </div>
</div>

<div class="w-full h-2 -mt-2 " />
<div class="flex bg-white text-white overflow-hidden py-3 ">
  <div class="h-2 -mt-1 flex flex-none">

    {#each blobs_left as left}
      <div id="blb" class=" flex justify-center items-center">
        <div class="bg-white rounded-sm w-3 h-3" />
      </div>
    {/each}

    <div id="blb" class=" flex justify-center items-center">
      <div class="bg-white rounded-sm w-3 h-3" />
    </div>

    {#each blobs_right as rgt}
      <div id="blb" class=" flex justify-center items-center">
        <div class="bg-white rounded-sm w-3 h-3" />
      </div>
    {/each}

  </div>
</div>
<div
  class="w-full h-2 -mt-2 bg-repeat"
  style="background-image:url(images/header-bottom-border.png)" />
{#if loading}
  <div
    class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex
    sm:items-center sm:justify-center">

    <div class="fixed inset-0 transition-opacity">

      <div class="absolute inset-0 bg-gray-500 opacity-75" />

    </div>
    {#if loading_success}
      <div
        class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl
        transform transition-all sm:max-w-sm sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div>
          <div
            class="mx-auto flex items-center justify-center h-12 w-12
            rounded-full bg-green-100">

            <svg
              class="h-6 w-6 text-green-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24">

              <path
                in:draw={{ duration: 1000, delay: 0 }}
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7" />

            </svg>

          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline">
              Successful Booking
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                Thankyou. We will be in touch soon to confirm your preferred
                booking time.
              </p>

            </div>
          </div>

        </div>

      </div>
    {/if}
    {#if loading_failure}
      <div
        class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl
        transform transition-all sm:max-w-sm sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div>
          <div
            class="mx-auto flex items-center justify-center h-12 w-12
            rounded-full bg-red-100">

            <svg
              class="h-6 w-6 text-red-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 20 20">
              <path
                in:draw={{ duration: 1000, delay: 0 }}
                stroke-width="2"
                d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711
                4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166
                3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071
                5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976
                15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929
                15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342
                16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289
                14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237
                4.68342 4.29289 4.29289Z" />

            </svg>

          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline">
              Booking failed
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                Our apologies. Please check your internet and try again.
              </p>
            </div>
          </div>

        </div>

      </div>
    {/if}
  </div>
{/if}

<div class="bg-white-300 px-5 sm:px-20 pt-5 pb-10">
  <form
    id="online_booking"
    method="POST"
    on:submit|preventDefault={form_submit}
    class="">
    {#if token.length > 0}
      <div class=" md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1 pt-5">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 " style="color:#bd73b0">
              10 to 10 Inc.
              <br />
              <br />
              <!-- <span class="text-md">  Need a licenced plumber for your home, business, development or
              property management clients?
              </span> -->
            </h3>
            <h3 class="text-sm leading-6 text-gray-900">Book an appointment</h3>

          </div>
        </div>
        <div class="mt-5 md:col-span-2 py-5 px-5 bg-white shadow rounded">

          <!-- @submit="form_submit" -->

          <div class=" grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label
                for="select_date"
                class="block text-sm font-medium leading-5 text-gray-700">
                Preferred Date
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  bind:value={selectedDate}
                  min={minDate}
                  placeholder="21/01/2020"
                  id="select_date"
                  type="date"
                  class="flex-1 form-input block w-full rounded-md transition
                  duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <!-- @input="changeCurrentDate($event)":class="error_begin_date"
                :value="selectedDate" -->
              </div>
              {#if error_begin_date}
                <p v-if="error_begin_date" class="mt-2 text-sm text-red-600">
                  You must submit a preferred date.
                </p>
              {/if}
            </div>

            <div class="sm:col-span-3">
              <label
                for="startBy"
                class="block text-sm font-medium leading-5 text-gray-700">
                Preferred Time
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  bind:value={begin_after}
                  placeholder="10:00"
                  id="startBy"
                  type="time"
                  pattern="[0-2]&#123;1}[0-9]&#123;1}[:]&#123;1}[0-5]&#123;1}[0]&#123;1}"
                  class="flex-1 form-input block w-full rounded-md transition
                  duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <!-- :class="error_begin_after"
                v-model="begin_after"
                @input="changeTimeBegin($event)" -->
              </div>
              {#if error_begin_after}
                <p v-if="error_begin_after" class="mt-2 text-sm text-red-600">
                  You must submit a preferred time. For example 13:00 or 1:00 pm
                </p>
              {/if}
            </div>
            <div class="sm:col-span-6">
              <label
                for="startBy"
                class="block text-sm font-medium leading-5 text-gray-700">
                Duration (in hours)
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  class="form-input block w-full transition duration-150
                  ease-in-out sm:text-sm sm:leading-5"
                  bind:value={duration} />

              </div>
              {#if error_begin_after}
                <p v-if="error_begin_after" class="mt-2 text-sm text-red-600">
                  You must submit a preferred time. For example 13:00 or 1:00 pm
                </p>
              {/if}
            </div>
            <div v-if="!isAuto" class="sm:col-span-6">
              <label
                for="cat"
                class="w-full text-sm font-medium leading-5 text-gray-700">
                Choose the service
              </label>
              <div class="mt-1 rounded-md shadow-sm ">
                <select
                  bind:value={job}
                  id="cat"
                  class="capitalize h-10 form-select block w-full transition
                  duration-150 ease-in-out sm:text-sm sm:leading-5">
                  <option value="">Select</option>
                  <option value="Item A">Item A</option>
                  <option value="Item B">Item B</option>
                  <option value="Item C">Item C</option>

                </select>
              </div>
            </div>

            <!-- {#if job !== 'Annual Test'}
              <div class="sm:col-span-3">
                <label
                  for="not_annual_selected"
                  class="block text-sm font-medium leading-5 text-gray-700">
                  Specifically?
                </label>
                <div class="mt-1 rounded-md shadow-sm ">
                  <select
                    bind:value={job_specific}
                    id="not_annual_selected"
                    class="capitalize h-10 form-select block w-full transition
                    duration-150 ease-in-out sm:text-sm sm:leading-5">
                    {#each jobs_sp as job_sp}
                      <option value={job_sp.id}>{job_sp.text}</option>
                    {/each}
                  </select>
                </div>
              </div>
            {/if} -->
            <div class="sm:col-span-6">
              <label
                for="notes"
                class="block text-sm font-medium leading-5 text-gray-700">
                Notes
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <textarea
                  bind:value={notes}
                  v-model="notes"
                  id="notes"
                  class="form-input block w-full transition duration-150
                  ease-in-out sm:text-sm sm:leading-5" />
              </div>
            </div>
            {#if isAuto}
              <div class="sm:col-span-6">
                {#if ready}
                  <label
                    for="street_address"
                    class="block text-sm font-medium leading-5 text-gray-700
                    mb-1">
                    Address
                  </label>
                  <Map />
                {/if}
              </div>
            {/if}
            <!-- {#if !isAuto}
              <div v-if="!isAuto" class="sm:col-span-6">
                <label
                  for="street_address"
                  class="block text-sm font-medium leading-5 text-gray-700">
                  Street address
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    v-model="addy_street"
                    bind:value={addy_street}
                    id="street_address"
                    :class="error_addy_street"
                    class="form-input block w-full transition duration-150
                    ease-in-out sm:text-sm sm:leading-5" />
                </div>
                {#if error_addy_street}
                  <p v-if="error_addy_street" class="mt-2 text-sm text-red-600">
                    Your street address is required.
                  </p>
                {/if}
              </div>

              <div v-if="!isAuto" class="sm:col-span-2">
                <label
                  for="city"
                  class="block text-sm font-medium leading-5 text-gray-700">
                  City
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    bind:value={addy_city}
                    v-model="addy_city"
                    id="city"
                    :class="error_addy_city"
                    class="form-input block w-full transition duration-150
                    ease-in-out sm:text-sm sm:leading-5" />
                </div>
                {#if error_addy_city}
                  <p v-if="error_addy_city" class="mt-2 text-sm text-red-600">
                    You must enter a city.
                  </p>
                {/if}
              </div>

              <div v-if="!isAuto" class="sm:col-span-2">
                <label
                  for="state"
                  class="block text-sm font-medium leading-5 text-gray-700">
                  State / Province
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    bind:value={addy_state}
                    v-model="addy_state"
                    id="state"
                    :class="error_addy_state"
                    class="form-input block w-full transition duration-150
                    ease-in-out sm:text-sm sm:leading-5" />
                </div>
                {#if error_addy_state}
                  <p v-if="error_addy_state" class="mt-2 text-sm text-red-600">
                    You must have a state.
                  </p>
                {/if}
              </div>

              <div v-if="!isAuto" class="sm:col-span-2">
                <label
                  for="zip"
                  class="block text-sm font-medium leading-5 text-gray-700">
                  Postcode
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    minlength="4"
                    maxlength="4"
                    bind:value={addy_zip}
                    v-model="addy_zip"
                    id="zip"
                    class="form-input block w-full transition duration-150
                    ease-in-out sm:text-sm sm:leading-5" />

                </div>
                {#if error_addy_zip}
                  <p v-if="error_addy_zip" class="mt-2 text-sm text-red-600">
                    Your postcode must be 4 characters.
                  </p>
                {/if}
              </div>
            {/if} -->
          </div>

        </div>

      </div>
      <div class="hidden sm:block pt-5">
        <div class="py-5">
          <div class="border-t border-gray-200" />
        </div>
      </div>
    {/if}

    {#if token.length > 0}
      <div in:fade out:fade class=" md:grid md:grid-cols-3 md:gap-6 ">
        <div class="md:col-span-1 pt-5 mb-5">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Contact details
            </h3>
            <p class="mt-1 text-sm leading-5 text-gray-600">
              Please provide your contact details.
            </p>
          </div>
        </div>
        <div class="mt-5 mb-5 md:col-span-2 py-5 px-5 bg-white shadow rounded">

          <!-- @submit="form_submit" -->

          <div class=" grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">

            <div class="sm:col-span-3">
              <label
                for="first_name"
                class="block text-sm font-medium leading-5 text-gray-700">
                First name
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  class="form-input block w-full transition duration-150
                  ease-in-out sm:text-sm sm:leading-5"
                  bind:value={name_first} />

                <!-- @input="error_name_first = null"
                v-model="name_first"
                id="boo_first_name"
                :class="error_name_first" -->
              </div>
              {#if error_name_first}
                <p class="mt-2 text-sm text-red-600">
                  You must submit a first name.
                </p>
              {/if}
            </div>

            <div class="sm:col-span-3">
              <label
                for="last_name"
                class="block text-sm font-medium leading-5 text-gray-700">
                Last name
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  class="form-input block w-full transition duration-150
                  ease-in-out sm:text-sm sm:leading-5"
                  bind:value={name_last} />
                <!-- @input="error_name_last = null"
                v-model="name_last"
                id="last_name"
                :class="error_name_last" -->
              </div>
              {#if error_name_last}
                <p v-if="error_name_last" class="mt-2 text-sm text-red-600">
                  You must submit a last name.
                </p>
              {/if}
            </div>

            <div class="sm:col-span-6 ">
              <label
                for="phone_number"
                class="block text-sm font-medium leading-5 text-gray-700">
                Mobile
              </label>
              <div class="mt-1 flex shadow-sm">
                <div
                  class="border-b border-t border-l rounded-l flex items-center">
                  <select
                    aria-label="Country"
                    class="form-select h-full py-0 pl-3 pr-7 border-transparent
                    bg-transparent text-gray-500 sm:text-sm sm:leading-5"
                    bind:value={country_code}>
                    <option>AU</option>
                    <option>PH</option>
                    <option>ID</option>
                    <option>IND</option>

                    <option>NZ</option>
                    <option>SG</option>
                  </select>
                </div>

                <input
                  maxlength={mobile_maxlength}
                  id="phone_number"
                  class="py-2 border-t border-b border-r rounded-r block w-full
                  pl-2 sm:text-sm sm:leading-5"
                  placeholder={mobile_placeholder}
                  bind:value={mobile} />
              </div>
              {#if error_mobile}
                <p class="mt-2 text-sm text-red-600">
                  Your mobile must be of a format {mobile_format} characters
                  long.
                </p>
              {/if}
            </div>
            <!-- <div class="sm:col-span-4">
              <label
                for="phone_number"
                class="block text-sm font-medium leading-5 text-gray-700">
                Mobile
              </label>
              <div class="mt-1 rounded-md shadow-sm">

           
                <input
                  :maxlength="mobile_maxlength"
                  :class="error_mobile"
                  id="phone_number"
                  class="form-input block w-full sm:text-sm sm:leading-5"
                  :placeholder="mobile_placeholder"
                  bind:value={mobile}
                  v-model="mobile" />
              </div>
              {#if error_mobile}
                <p class="mt-2 text-sm text-red-600">
                  Your mobile must be of a format characters long.
                </p>
              {/if}
            </div> -->
            <div class="sm:col-span-6">
              <label
                for="email"
                class="block text-sm font-medium leading-5 text-gray-700">
                Email address
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <!-- @input="error_email = null" -->
                <input
                  bind:value={email}
                  v-model="email"
                  id="email"
                  type="email"
                  :class="error_email"
                  class="form-input block w-full transition duration-150
                  ease-in-out sm:text-sm sm:leading-5" />
              </div>
              {#if error_email}
                <p
                  v-if="error_email"
                  class="flex items-center mt-2 text-sm text-red-600">
                  A correct email address is required.
                </p>
              {/if}
            </div>

          </div>
          <div class="mt-8 border-t border-gray-200 pt-5">
            <div class="flex justify-end">

              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  class="inline-flex justify-center py-2 px-4 border
                  border-transparent text-sm leading-5 font-medium rounded-md
                  text-white bg-white hover:bg-white focus:outline-none
                  focus:border-green-700 focus:shadow-outline-white
                  active:bg-white transition duration-150 ease-in-out"
                  style=" color: #ffffff; background: #bd73b0;">
                  Book
                </button>
              </span>
            </div>
          </div>
        </div>

      </div>
    {/if}
  </form>
</div>
